// AdminLayout.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from './header';
import AlertCont from '../components/ToastContainer';
import { Affix } from 'antd';
import Notification from '../components/Notification';
import NotiAlert from '../components/NotiAlert';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import { useGetnotiQuery, useLazyGetallQuery } from '../redux/user';
import consumer from '../components/cable';
function AdminLayout({ children }) {
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = user ? user.id : null;
  const [messages, setMessages] = useState({});
  const [data, setData] = useState({})
  const [getAll, { isLoading, refetch }] = useLazyGetallQuery()
  const showNotifications = useSelector((state) => state.user.shownotification);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const notificationsChannel = consumer.subscriptions.create('NotificationsChannel', {
      received: (notification) => {

        toast.success(notification.content, {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    });

    return () => {
      notificationsChannel.unsubscribe();
    };
  }, [notifications]);


  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: 'Pamp Report',
        backgroundColor: "#F9B015",
        borderColor: '#F9B015',
        barThickness: 15,
        borderRadius: 40,
        borderSkipped: false,
        data: [65, 59, 80, 81, 56, 55, 40],

      }
    ],

  };
  useEffect(() => {

    const fetchNotifications = async () => {

      try {

        const response = await getAll({ scope: 'notifications' }); // Replace with your actual function to get notifications
        console.log(response,'dsdsds')
        setData(response.data)

     
      } catch (error) {
   
        localStorage.clear();
        window.location.href('/')
        console.log('Error fetching notifications:', error);
      }
    };

   // const interval = setInterval(() => {
      fetchNotifications();
    //}, 6665000); // Poll every 5 seconds

    
    //return () => clearInterval(interval); // Cleanup on component unmount
  }, []); // Empty dependency array to run the effect once on mount

  return (
    <div className='layout-container'>
      <div className='sidebar-layout'>
        <Sidebar />
      </div>

      <div className="content-layout no-scrollbar">
        <Affix>
          <div className='header-layout h-[100px] bg-bg'>
            <Header notifications={data ? data.notifications : []} admins={data?.online_admins} has_unread={data?.has_unread} />
          </div>
        </Affix>
        {children}
        <AlertCont />



      </div>
    </div>
  );
}

export default AdminLayout;
