import { Avatar } from "antd";
import React from "react";


import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { formatDate, truncateText } from "../function";
import axios from "axios";
import { backendURL } from "../redux/http";
const { confirm } = Modal;
const PayoutRow = ({ data }) => {


    console.log(data);

    
    const showConfirm = async () => {
      try {
       confirm({
          title: 'Confirm withdrawal?',
          icon: <ExclamationCircleFilled />,
          content: 'Are you sure you want to approve this withdrawal?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: async () => {
            const response = await axios.post(
              backendURL + `/vendor/withdraw/process/${data.id}`,
              { scope: 'approved' }
            );
            alert(response.data.message);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      } catch (error) {
        console.error('Error during confirmation:', error);
        // Handle the error as needed
      }
    };
    
    return (
       <div key={data.id}   className="tred " 
     > 
      

      <div className="flex-item" onClick = {()=> console.log(data.id)}>
          <div className="">{data?.withdrawable?.username||'unknown'}</div>
          </div>       
           <div className="flex-item">{data.amount}</div>
        <div className="flex-item">{data.method}</div>
          <div className="flex-item">{truncateText(data.reference,15)}</div>
        <div className="flex-item">{formatDate(data.created_at)}</div>
        <div className="flex-item d-flex ">
         <div onClick={showConfirm} className="d-flex action unapproved">Approve</div>
          <div className="d-flex decline action">Decline</div>      
        </div>   
      
        </div>
    );
};

export default   PayoutRow;