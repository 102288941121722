import React, { useState, useEffect, useRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Input, Radio, Select, Typography, Avatar, Drawer } from 'antd';
import { useGetallQuery } from '../redux/user';
import Checkbox from '../components/Checkbox';
import { useAddSubscriptionMutation } from '../redux/user';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLazyGetallQuery } from "../redux/user";
import TextInput from '../components/Input';
import SelectInput from '../components/Select';
import userImage from '../assets/images/userimg.png';
import { useCreateMutation } from '../redux/user';
import { backendURL } from '../redux/http';
import axios from 'axios';
import { DateInput } from '../function';
const { Text } = Typography;

const EditUser = ({ isOpen, setOpen, data, refetch }) => {
    const [createUser] = useCreateMutation()
    const [imageSource, setImageSource] = useState(null);
    const [file, setImageFile] = useState()
    const [formInfo, setFormData] = useState(data);
    const imageInputRef = useRef(null);
    const formRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file)

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSource(event.target.result);
            };

            reader.readAsDataURL(file);
        }
    };


    const handleSave = async () => {
        const loadingToastId = toast.info('Updating user...', {
            position: 'top-right',
            autoClose: false, // Do not auto close loading toast
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
        });
        try {

            const formData = new FormData();

            // Loop through form data fields and append them
            Object.entries(formInfo).forEach(([field, value]) => {
                // Append user fields with appropriate naming
                formData.append(`user[${field}]`, value);
            });
            formInfo?.email_confirmed == true && formData.append('email_confirmed', formInfo?.email_confirmed)
            // Append the image file
            file && formData.append('image', file);
            formData.append('id', data.id);

            // Append additional fields like type and scope
            formData.append('model', 'User'); // Replace with the actual type value
            formData.append('scope', 'user'); // Replace with the actual scope value

            // Call the createUser mutation with FormData
            //   const { data } = await createUser(formData);
            const response = await axios.patch(backendURL + "/admin/update", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`

                },
            });
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }

            // Hard reset form
            if (formRef.current) {
                formRef.current.reset();
            }
            refetch()
            toast.dismiss(loadingToastId);
            console.log(response.data)
            toast.success('User created successfully!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            // Handle successful creation
        } catch (error) {
            toast.dismiss(loadingToastId);
            toast.error('Something went wrong!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };


    const handleChange = (field, value) => {
        setFormData((prevData) => ({ ...prevData, [field]: value }));

    };


    return (
        <Drawer title='Update User' width={800} placement="right" closable={true} onClose={() => setOpen(!isOpen)} open={isOpen}>


            <form ref={formRef}>
                <div className="flex gap-5">

                    <div className="flex-1">
                        <h2 className="mt-[50px] title-med">Name</h2>
                        <div className='flex gap-5'>

                            <TextInput
                                className={'flex-1 input'}
                                placeholder={'First Name'}
                                onChange={(e) => handleChange('username', e.target.value)}
                                value={formInfo.username}
                            />
                            <TextInput
                                className={'flex-1 input'}
                                placeholder={'Last Name'}
                                onChange={(e) => handleChange('name', e.target.value)}
                                value={formInfo.name}
                            />
                        </div>


                        <h2 className="mt-[50px] title-med">Email</h2>

                        <TextInput
                            disabled={true}
                            className={'flex-1 input'}
                            placeholder={'Email Address'}
                            onChange={(e) => handleChange('email', e.target.value)}
                            type={'email'}
                            value={formInfo.email}
                        />
                        <h2 className="mt-[50px] title-med">Phone Number</h2>
                        <TextInput
                            className={'flex-1 input'}
                            placeholder={'Phone'}
                            onChange={(e) => handleChange('phone', e.target.value)}
                            value={formInfo.phone}
                        />
                        <h2 className="mt-[50px] title-med">DOB</h2>
                        <TextInput
                            className={'flex-1 input'}
                            placeholder={'01/10/2024'}
                            type={'input'}

                            value={formInfo?.date_of_birth}
                            onChange={(e) => handleChange('date_of_birth', e.target.value)}

                        />

                        <h2 className="mt-[50px] title-med">Gender</h2>
                        <SelectInput
                            defaultValue={formInfo.gender}
                            className={'input'}
                            onChange={(e) => handleChange('gender', e)}
                            placeholder={'Select Gender'}
                            data={[
                                {
                                    name: 'male',
                                    id: 1
                                },
                                {
                                    id: 2,
                                    name: 'female'
                                },
                            ]}
                        />


                    </div>
                    <div className="flex-1">

                        <h2 className="mt-[50px] title-med">Address</h2>
                        <TextInput
                            className={'flex-1 input'}
                            value={formInfo.address}
                            placeholder={'Insert location'}
                            onChange={(e) => handleChange('address', e.target.value)}

                        />

                         <h2 className="mt-[65px] title-med">Influencer Code</h2>
                        <TextInput
                            className={'flex-1 input'}
                            disabled
                            value={formInfo.influencer_code}
                            placeholder={'nil'}
                            onChange={(e) => handleChange('address', e.target.value)}

                        />
                         <h2 className="mt-[50px] title-med">Influencer Rate</h2>
                        <TextInput
                            className={'flex-1 input'}
                            value={formInfo.influencer_rate}
                            
                            type={'number'}
                            placeholder={'nil'}
                            onChange={(e) => handleChange('influencer_rate', e.target.value)}

                        />

                        <h2 className="mt-[50px] title-med">Role</h2>
                        <SelectInput
                            className={'input'}
                            onChange={(e) => handleChange('role', e)}
                            defaultValue={formInfo.role}
                            placeholder={'Select Role'}
                            data={[

                                {
                                    id: 2,
                                    name: 'user'
                                },
                                {
                                    id: 3,
                                    name: 'influencer'
                                },
                                {
                                    id: 5,
                                    name: 'admin'
                                },
                                {
                                    id: 4,
                                    name: 'other'
                                },
                            ]}
                        />

                        <div className='ml-3 flex wrap gap-[10px] mt-[120px]'>
                            <Checkbox label={'Confirm phone?'} checked={formInfo.phone_confirmed} onChange={(e) => handleChange('phone_confirmed', e)} />

                        </div>
                        <div className="flex gap-3 justify-end mt-[140px] mb-[140px]">
                            <div style={{ width: 100 }} className="action d-flex unapproved" onClick={handleSave}>
                                Update
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </Drawer>
    );
};

export default EditUser;
