import { Avatar } from "antd";
import React from "react";
import bin from '../assets/svgs/bin.svg'
import edit from '../assets/svgs/edit.svg'
import axios from "axios";
import { backendURL } from "../redux/http";
import { Button, Modal, Space } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import EditDropdown from "./Editmenu";
import { timeAgo } from "../function";
const { confirm } = Modal;

const ReviewsRow = ({ data, refetch }) => {
  console.log(data);

  const approve = async (state) => {
    const response = await axios.patch(backendURL + "/admin/update", {
      model: 'Rating',
      scope: 'rating',
      id: data.id,
      rating: { approved: state }
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    });

    refetch();
  };

  // Inline style definitions for buttons
  const approveButtonStyle = data.approved
    ? { backgroundColor: '#4CAF50', color: 'white' }
    : { backgroundColor: '#FFC107', color: 'black' };

  const rejectButtonStyle = { backgroundColor: '#f44336', color: 'white' };

  return (
    <div key={data.id} style={{ height: 150 }} className="tred">
      <div className="flex-item">{data.user.username}</div>
      <div className="flex-item">{data.vendor.username}</div>
      <div className="flex-item">{data.rating}</div>
      <div className="flex-item">{data.description}</div>
      <div className="flex-item">{data.updated_at ? timeAgo(data.updated_at) : ''}</div>
      <div className="flex-item gap-[10px] d-flex">
        {/* Approve Button with smaller and rounded styles */}
        <button
          onClick={() => approve(true)}
          style={{
            ...approveButtonStyle,
            padding: '8px 12px',
            fontSize: '12px',
            borderRadius: '20px', // rounded corners
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
          }}
        >
          {data.approved ? "Approved" : 'Approve'}
        </button>
        {/* Reject Button with smaller and rounded styles */}
        <button
          onClick={() => approve(false)}
          style={{
            ...rejectButtonStyle,
            padding: '8px 12px',
            fontSize: '12px',
            borderRadius: '20px', // rounded corners
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
          }}
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default ReviewsRow;
