

import React, { useState } from "react";
import { Layout, Affix, Avatar, Dropdown, Menu, notification } from 'antd';
import { BellOutlined, SettingOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { ReactComponent as Bell } from "../assets/svgs/bell.svg"
import { ReactComponent as Bellsactive } from "../assets/svgs/bellactive.svg"
import { ReactComponent as LogoutIcon } from "../assets/svgs/log-out.svg";
import Notification from "../components/Notification"; // Import the Notification component
import admin from '../assets/images/admin.jpg';
import { useNavigate } from "react-router-dom";
import NotiAlert from "../components/NotiAlert";
import { useDispatch } from "react-redux";
import { setShowNoti } from "../redux/login";
import AvatarGroup from "../components/AvatarGroup";
import NotiDrawer from "../components/NotiDrawer";
import UserDrawer from "../components/UserDrawer";
const Header = ({has_unread,admins=[],notifications}) => {
  const { Header } = Layout;
  const dispatch= useDispatch()
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  const [showNotifications, setShowNotifications] = useState(false);
  const [userOpened,setUserOpened]=useState(false)
  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleProfile = () => {
    setUserOpened(true)
  };

  const handleSettings= () => {
    navigate('/admin/settings')
  };


  const handleToggleNotifications = () => {
    setShowNotifications(!showNotifications)
  };
  
  const items = [
    {
      label: (
        <>
          <UserOutlined style={{ marginRight: '8px', color: '#B0EBBD' }} />
          Profile
        </>
      ),
      key: '0',
      onClick: handleProfile,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <>
          <SettingOutlined style={{ marginRight: '8px', color: '#B0EBBD' }} />
          Settings
        </>
      ),
      key: '1',
      onClick: handleSettings,
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div className="d-flex content-center">
          <LogoutIcon style={{ marginRight: '8px', color: '#B0EBBD' }} />
          <span className="mt-[-5px]">Logout</span>
        </div>
      ),
      key: '3',
      onClick: handleLogout,
    },
  ];
console.log(user)
  return (
    <div className="header">
      <div className="header-content">
        <div className="header-welcome">
          Welcome {' '}
        </div>
        <div style={{}} className="header-bell">
          {
            has_unread?<Bellsactive className="header-bell-icon  cursor"  onClick={handleToggleNotifications}/>:          <Bell className="header-bell-icon  cursor"  onClick={handleToggleNotifications}/>


          }
                  <Dropdown menu={{ items }} placement="bottomRight">

          <span className="header-user cursor">{user?.first_name}</span>
          </Dropdown>
           

              {
            admins.length!=0?<AvatarGroup  admins={admins} /> :<Avatar className="cursor" size='large' key={admin.id} src={user?.photo_url|| require('../assets/images/userimg.png')} alt={user?.username} />

              }




        </div>
      </div>
<NotiDrawer notifications={notifications} setOpen={setShowNotifications} open={showNotifications}/>
<UserDrawer user={user} setOpen={setUserOpened} open={userOpened}/>
    </div>
  );
};

export default Header;
// NotificationService.create_alert(
//   title: 'New Vendor',
//   body: "#{self.username} just created an account"
// )
// ActionCable.server.broadcast('notifications_channel', {
//   content: "New vendor- #{self.username}"
// })